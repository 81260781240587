import React from "react";
// import { Link } from "gatsby"

import { Heading, Container } from "@chakra-ui/react";

// import { MdCheckCircle, MdSettings } from "@chakra-ui/icons";
import { FiAward } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Map from "../components/mapComponent";

const RatbadgerPage = () => (
  <Layout>
    <SEO title="The Ratbadger" />

    {/* <Stack w="80%" mx="auto" direction={["column", "row"]} spacing="2rem"> */}
    <Container
      p="2rem"
      // h="400px"
      bg="gray.100"
      borderRadius="xl"
      centerContent
    >
      <Heading>Nothing is known about the ratbadger.</Heading>
      {/* <Text mt={4} fontSize="xl">
        Inform the world about the dangers of the Bearbat.
      </Text> */}
    </Container>
    {/* </Stack> */}

    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
);

export default RatbadgerPage;
